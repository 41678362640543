<template>
  <div>
    <b-modal id="modal-1" title="Tambah Paket Pekerjaan" ok-disabled no-close-on-backdrop size="lg" hide-footer>
      <div>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <!--  This field is required-->
              <b-col md="12">
                <b-form-group label="Nama Kegiatan" label-for="Nama Kegiatan">
                  <validation-provider #default="{ errors }" rules="required" name="Nama Kegiatan ">
                    <b-form-input v-model="input.nama_kegiatan" :state="errors.length > 0 ? false : null"
                      placeholder="Nama Kegiatan" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Kategori Paket Pekerjaan" label-for="username">
                  <v-select v-model="input.id_pekerjaan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="nama_pekerjaan" :options="jenis_pekerjaan" />
                </b-form-group>
              </b-col>
              <b-col>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Nomor Kontrak" label-for="Nomor Kontrak">
                      <validation-provider #default="{ errors }" rules="required" name="Nomor Kontrak">
                        <b-form-input v-model="input.no_kontrak" :state="errors.length > 0 ? false : null"
                          placeholder="No Kontrak" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group label="Nilai Kontrak" label-for="Nilai Kontrak">
                      <validation-provider #default="{ errors }" rules="required" name="Anggaran">
                        <currency-input v-model="input.pagu_anggaran" class="form-control"
                          :state="errors.length > 0 ? false : null"> </currency-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group label="Tanggal Kontrak" label-for="Tanggal Kontrak">
                      <validation-provider #default="{ errors }" rules="required" name="Tanggal Kontrak">
                        <flat-pickr v-model="input.tgl_kontrak" :state="errors.length > 0 ? false : null"
                          :config="{ dateFormat: 'd-m-Y' }" class="form-control" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Tanggal PHO" label-for="Tanggal PHO">
                      <validation-provider #default="{ errors }" rules="required" name="Tanggal PHO">
                        <flat-pickr v-model="input.tgl_pho" :state="errors.length > 0 ? false : null"
                          :config="{ dateFormat: 'd-m-Y' }" class="form-control" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group label="Tanggal FHO" label-for="Tanggal FHO">
                      <validation-provider #default="{ errors }" name="Tanggal FHO">
                        <flat-pickr v-model="input.tgl_fho" :state="errors.length > 0 ? false : null"
                          :config="{ dateFormat: 'd-m-Y' }" class="form-control" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>


                  </b-col>
                </b-row>
              </b-col>
              <!-- <b-col md="12">
                <b-form-group label="Ruas Jalan" label-for="Ruas Jalan">
                  <validation-provider #default="{ errors }" rules="required" name="Ruas Jalan">
                    <v-select v-model="input.id_ruas_jalan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @input="kondisi_ruas_jalan()" label="nama_ruas_jalan" :options="ruas_jalan" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
              <!-- <b-col md="12" v-if="kodisiJalan != '' && kodisiJalan != {} && input.id_ruas_jalan.id_ruas_jalan != ''">

                <div v-if="input.id_pekerjaan.id_pekerjaan != '4' && input.id_pekerjaan.id_pekerjaan != '3'">

                  <h5>Aspal</h5>
                  <b-row>
                    <b-col md="3">Baik<br> <strong class="text-primary">{{ kodisiJalan.s_aspal_baik }}
                      </strong>
                      <b-form-group v-if="kodisiJalan.s_aspal_baik != '0' && kodisiJalan.s_aspal_baik != '0.00'">
                        <b-form-input id="basicInput" placeholder="s_aspal_baik " v-model="jalan.aspal_baik" />
                      </b-form-group>
                    </b-col>
                    <b-col md="3">Sedang<br> <strong class="text-primary">{{ kodisiJalan.s_aspal_sedang }}</strong>
                      <b-form-group v-if="kodisiJalan.s_aspal_sedang != '0' && kodisiJalan.s_aspal_sedang != '0.00'">
                        <b-form-input id="basicInput" placeholder="s_aspal_sedang " v-model="jalan.aspal_sedang" />
                      </b-form-group>
                    </b-col>
                    <b-col md="3">Rusak Ringan<br> <strong class="text-primary">{{ kodisiJalan.s_aspal_rusak_ringan
                    }}</strong>
                      <b-form-group
                        v-if="kodisiJalan.s_aspal_rusak_ringan != '0' && kodisiJalan.s_aspal_rusak_ringan != '0.00'">
                        <b-form-input id="basicInput" placeholder="s_aspal_rusak_ringan "
                          v-model="jalan.aspal_rusak_ringan" />
                      </b-form-group>
                    </b-col>
                    <b-col md="3">RusakBerat<br> <strong class="text-primary">{{ kodisiJalan.s_aspal_rusak_berat
                    }}</strong>
                      <b-form-group
                        v-if="kodisiJalan.s_aspal_rusak_berat != '0' && kodisiJalan.s_aspal_rusak_berat != '0.00'">
                        <b-form-input id="basicInput" placeholder="s_aspal_rusak_berat "
                          v-model="jalan.aspal_rusak_berat" />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <h5>Beton</h5>
                  <b-row>
                    <b-col md="3">Baik<br> <strong class="text-primary">{{ kodisiJalan.s_beton_baik }}</strong>
                      <b-form-group v-if="kodisiJalan.s_beton_baik != '0' && kodisiJalan.s_beton_baik != '0.00'">
                        <b-form-input id="basicInput" placeholder="s_beton_baik " v-model="jalan.beton_baik" />
                      </b-form-group>
                    </b-col>
                    <b-col md="3">Sedang<br> <strong class="text-primary">{{ kodisiJalan.s_beton_sedang }}</strong>
                      <b-form-group v-if="kodisiJalan.s_beton_sedang != '0' && kodisiJalan.s_beton_sedang != '0.00'">
                        <b-form-input id="basicInput" placeholder="s_beton_sedang " v-model="jalan.beton_sedang" />
                      </b-form-group>
                    </b-col>
                    <b-col md="3">Rusak Ringan<br> <strong class="text-primary">{{ kodisiJalan.s_beton_rusak_ringan
                    }}</strong>
                      <b-form-group
                        v-if="kodisiJalan.s_beton_rusak_ringan != '0' && kodisiJalan.s_beton_rusak_ringan != '0.00'">
                        <b-form-input id="basicInput" placeholder="s_beton_rusak_ringan "
                          v-model="jalan.beton_rusak_ringan" />
                      </b-form-group>
                    </b-col>
                    <b-col md="3">RusakBerat<br> <strong class="text-primary">{{ kodisiJalan.s_beton_rusak_berat
                    }}</strong>
                      <b-form-group
                        v-if="kodisiJalan.s_beton_rusak_berat != '0' && kodisiJalan.s_beton_rusak_berat != '0.00'">
                        <b-form-input id="basicInput" placeholder="s_beton_rusak_berat "
                          v-model="jalan.beton_rusak_berat" />

                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-col> -->
            </b-row>
            <hr>
            <b-row>
              <b-col cols="12">
                <b-button variant="primary" type="submit" @click.prevent="validationForm">
                  <feather-icon icon="SaveIcon" size='15' /> Simpan
                </b-button>
                <b-button variant="warning" @click="close()" class="ml-1">
                  <feather-icon icon="XIcon" size='15' /> Keluar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>

  </div>
</template>

<script>

import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required
} from '@validations'
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BForm, BCol, BRow, BFormGroup, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CurrencyInput from '@/views/bpj/ajuan_kegiatan/usulan_kegiatan/component/CurrencyInput.vue'

export default {
  components: {
    BButton, vSelect,
    BModal, ValidationProvider, ValidationObserver, flatPickr,
    BAlert, BForm, BCol, BRow, BFormGroup, BFormInput, CurrencyInput
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selected: {},
      jalan: {},
      aspal_baik: {},
      beton_baik: {},
      required,
      input: {
        id_pekerjaan: { id_pekerjaan: '7', },
        id_ruas_jalan: { id_ruas_jalan: '' },
      },
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["kondisi_ruas_jalan", "SimpanAjuanKontrak"],
  methods: {
    kondisi_ruas_jalan() {
      this.$emit('kondisi_ruas_jalan', this.input.id_ruas_jalan)
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit("SimpanAjuanKontrak", this.input, this.jalan);
          this.close();
        }
      })
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
      this.input = {
        id_pekerjaan: { id_pekerjaan: '7', },
        id_ruas_jalan: { id_ruas_jalan: '' },
      };
      this.jalan = {}
    }
  },
  props: {
    ruas_jalan: {

    },
    kodisiJalan: {},
    jenis_pekerjaan: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>