<template>
  <div>
    <b-modal id="modal-3" title="Data Kontrak" ok-disabled no-close-on-backdrop size="lg" hide-footer>
      <b-card-text>
        <b-row>
          <b-col md="12">
            <!-- <h5>
              <small><strong>Tujuan : </strong> {{ rs_detail.tujuan }}</small><br>
              <small><strong>Indikator Tujuan : </strong> {{ rs_detail.indikator_tujuan }}</small><br>
              <small><strong>Sasaran : </strong> {{ rs_detail.sasaran }}</small><br>
              <small><strong>Indikator Sasaran : </strong> {{ rs_detail.indikator_sasaran }}</small><br>
            </h5>
            <hr>
            <h5><small>Kode/Nama Kegiatan</small><br>
              <strong>{{ rs_detail.kode_sub_kegiatan }} - {{ rs_detail.nama_sub_kegiatan }}</strong><br>
              <small><strong>indikatror : </strong></small><small>{{ rs_detail.indikatror }}</small><br>
              <small><strong>Kinerja : </strong></small><small>{{ rs_detail.kinerja }}</small>
              (<small>{{ rs_detail.satuan_sub_kegiatan }})</small><br>
            </h5>
            <hr> -->
            <b-row>

              <b-col md="8">

                <h6 class="text-primary"> <small><strong>Nama Pekerjaan : </strong></small><small>{{
                  rs_detail.nama_pekerjaan }} <br><strong>{{ rs_detail.nm_balai_bidang }}</strong></small>
                  <br><small><strong>Jenis Pekerjaan {{ rs_detail.jenis_pekerjaan }}</strong></small>
                </h6>
                <h6 class="text-primary"> </h6>
                <h6 class="text-primary"> <small><strong>Detail Pekerjaan
                    </strong></small><small>{{ rs_detail.detail_lokasi }}{{
                      rs_detail.nama_ruas_jalan }} - {{ rs_detail.nm_wil }}</small> <br>
                  <small><strong>Target Pekerjaan
                    </strong></small><small>{{ rs_detail.target_pekerjaan }} - {{
                      rs_detail.satuan }} </small>
                </h6>
              </b-col>
              <b-col md="4">
                <small>Pagu Anggaran</small>
                <h5 style="font-size: 18px;"><strong class="text-primary"><sup>Rp. </sup>{{ Rp(rs_detail.pagu_usulan)
                }}</strong></h5>
              </b-col>
            </b-row>
            <hr>
            <h5><feather-icon icon="GridIcon" size='15' /> Kontrak</h5>
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="8">
                    <h5><small>No Kontrak : <br><strong>{{ rs_detail.no_kontrak }}</strong></small> </h5>
                  </b-col>
                  <b-col md="4">
                    <small>Nilai Kontrak</small>
                    <h5 style="font-size: 18px;"><strong class="text-primary"><sup>Rp. </sup>{{
                      Rp(rs_detail.nilai_kontrak) }}</strong></h5>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-row>
                      <b-col md="3" style="margin-right: -30px;;">
                        <b-avatar size="30" variant="light-primary" rounded=""><feather-icon icon="CalendarIcon"
                            size='15' /></b-avatar>
                      </b-col>
                      <b-col md="8">
                        <h5><small>Tanggal Kontrak <br><strong>{{ rs_detail.tgl_kontrak }}</strong></small> </h5>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col md="4">
                    <b-row>
                      <b-col md="3" style="margin-right: -30px;;">
                        <b-avatar size="30" variant="light-primary" rounded=""><feather-icon icon="CalendarIcon"
                            size='15' /></b-avatar>
                      </b-col>
                      <b-col md="8">
                        <h5><small>Tanggal PHO <br><strong>{{ rs_detail.tgl_fho }}</strong></small> </h5>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="4">
                    <b-row>
                      <b-col md="3" style="margin-right: -30px;;">
                        <b-avatar size="30" variant="light-primary" rounded=""><feather-icon icon="CalendarIcon"
                            size='15' /></b-avatar>
                      </b-col>
                      <b-col md="8">
                        <h5><small>Tanggal PHO <br><strong>{{ rs_detail.tgl_pho }}</strong></small> </h5>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <a :href="rs_detail.file_kontrak" download="" target="_blank">
                      <small class="text-dark">File Kontrak </small>
                      <h5><b-avatar variant="warning" rounded="" size="20"><feather-icon icon="DownloadIcon"
                            size='15' /></b-avatar>
                        File Kontrak</h5>
                    </a>
                  </b-col>
                </b-row>
              </b-col>

            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BRow, BCol, BCard, BAvatar, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal, BButton, VBModal, BAlert, BRow, BCol, BCard, BAvatar, BBadge
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: [""],
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-3')
    }
  },
  props: {
    rs_detail: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>