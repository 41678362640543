var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-4","title":"Data Kontrak","ok-disabled":"","no-close-on-backdrop":"","size":"lg","hide-footer":""}},[_c('b-card-text',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('h6',{staticClass:"text-primary"},[_c('small',[_c('strong',[_vm._v("Nama Pekerjaan : ")])]),_c('small',[_vm._v(_vm._s(_vm.rs_detail.nama_pekerjaan)+" "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.rs_detail.nm_balai_bidang))])]),_c('br'),_c('small',[_c('strong',[_vm._v("Jenis Pekerjaan "+_vm._s(_vm.rs_detail.jenis_pekerjaan))])])]),_c('h6',{staticClass:"text-primary"}),_c('h6',{staticClass:"text-primary"},[_c('small',[_c('strong',[_vm._v("Detail Pekerjaan ")])]),_c('small',[_vm._v(_vm._s(_vm.rs_detail.detail_lokasi)+_vm._s(_vm.rs_detail.nama_ruas_jalan)+" - "+_vm._s(_vm.rs_detail.nm_wil))]),_vm._v(" "),_c('br'),_c('small',[_c('strong',[_vm._v("Target Pekerjaan ")])]),_c('small',[_vm._v(_vm._s(_vm.rs_detail.target_pekerjaan)+" - "+_vm._s(_vm.rs_detail.satuan)+" ")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('small',[_vm._v("Pagu Anggaran")]),_c('h5',{staticStyle:{"font-size":"18px"}},[_c('strong',{staticClass:"text-primary"},[_c('sup',[_vm._v("Rp. ")]),_vm._v(_vm._s(_vm.Rp(_vm.rs_detail.pagu_usulan)))])])])],1),_c('hr'),_c('h5',[_c('feather-icon',{attrs:{"icon":"GridIcon","size":"15"}}),_vm._v(" Input Kontrak")],1),_c('b-row',[_c('b-col',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nomor Kontrak","label-for":"Nomor Kontrak"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Nomor Kontrak"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"No Kontrak"},model:{value:(_vm.input.no_kontrak),callback:function ($$v) {_vm.$set(_vm.input, "no_kontrak", $$v)},expression:"input.no_kontrak"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nilai Kontrak","label-for":"username"}},[_c('validation-provider',{attrs:{"rules":"required","name":"First Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('currency-input',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.input.nilai_kontrak),callback:function ($$v) {_vm.$set(_vm.input, "nilai_kontrak", $$v)},expression:"input.nilai_kontrak"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"File Kontrak","label-for":"File Kontrak"}},[_c('b-form-file',{ref:"file",attrs:{"placeholder":"File type file PDF","id":"customFile","drop-placeholder":"Upload File Kontrak (.PDF)"},on:{"input":_vm.uploadFile},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('i',[_c('small',[_vm._v("**pastikan extensi file dalam bentuk .pdf")])])],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Kontrak","label-for":"Tanggal Kontrak"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Tanggal Kontrak"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null,"config":{ dateFormat: 'd-m-Y' }},model:{value:(_vm.input.tgl_kontrak),callback:function ($$v) {_vm.$set(_vm.input, "tgl_kontrak", $$v)},expression:"input.tgl_kontrak"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tanggal PHO","label-for":"Tanggal PHO"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Tanggal PHO"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null,"config":{ dateFormat: 'd-m-Y' }},model:{value:(_vm.input.tgl_pho),callback:function ($$v) {_vm.$set(_vm.input, "tgl_pho", $$v)},expression:"input.tgl_pho"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tanggal FHO","label-for":"Tanggal FHO"}},[_c('validation-provider',{attrs:{"name":"Tanggal FHO"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null,"config":{ dateFormat: 'd-m-Y' }},model:{value:(_vm.input.tgl_fho),callback:function ($$v) {_vm.$set(_vm.input, "tgl_fho", $$v)},expression:"input.tgl_fho"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }