<template>
  <b-row>
    <b-col md="6" sm="8" class="my-1">
      <h6 class="text-primary"> <feather-icon icon="GridIcon" size='15' /><strong> Daftar Kegiatan Tervalidasi</strong>
      </h6>
    </b-col>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" />
      </b-form-group>
    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <b-row v-if="data.item.nilai_kontrak != '0'">
            <b-col md="6">

              <!-- <h6 class="text-primary"> <small>Sub Kegiatan </small><br>{{ data.item.kode_sub_kegiatan }} {{
                data.item.nama_sub_kegiatan }}</h6> -->

              <h6 class="text-primary"> <small><strong>Nama Pekerjaan : </strong></small><small>{{
                data.item.nama_pekerjaan }} <br><strong>{{ data.item.nm_balai_bidang }}</strong></small>
                <br><small><strong>Kategori Paket {{ data.item.jenis_pekerjaan }}</strong></small>
              </h6>
              <h6 class="text-primary"> </h6>
              <h6 class="text-primary"> <small><strong>Detail Pekerjaan
                  </strong></small><small>{{ data.item.detail_lokasi }}{{
                    data.item.nama_ruas_jalan }} - {{ data.item.nm_wil }}</small> <br>
                <small><strong>Target Pekerjaan
                  </strong></small><small>{{ data.item.target_pekerjaan }} - {{
                    data.item.satuan }} </small>
              </h6>

            </b-col>
            <b-col md="2">
              <h5><small>Pagu Anggaran </small> <br><strong><sup>Rp. </sup>{{ Rp(data.item.pagu_usulan) }}</strong></h5>
              <h5><small>Nilai Kontrak </small> <br><strong><sup>Rp. </sup>{{ Rp(data.item.nilai_kontrak) }}</strong></h5>


            </b-col>
            <b-col md="4" v-show="data.item.nilai_kontrak == '0'">
              <b-row>
                <b-col md="5"><small></small> </b-col>
                <b-col md="7"> <b-badge variant=" info">
                    <b-badge variant="primary" block size="sm" v-if="data.item.nilai_kontrak < '1'" v-b-modal.modal-4
                      @click="DetailDatta(data.item)">
                      <feather-icon icon="EditIcon" size='15' /> Input Data Kontrak
                    </b-badge>
                  </b-badge></b-col>
              </b-row>



            </b-col>
            <b-col md="4" v-show="data.item.tgl_kontrak != null">
              <b-row>
                <b-col md="5"><small>No Kontrak</small> </b-col>
                <b-col md="7"><small><strong>{{ data.item.no_kontrak }}</strong></small></b-col>
              </b-row>

              <b-row>
                <b-col md="5"><small>Tanggal Kontrak</small> </b-col>
                <b-col md="7"><small><strong>{{ data.item.tgl_kontrak }}</strong></small></b-col>
              </b-row>

              <b-row>
                <b-col md="5"><small>Tanggal PHO</small> </b-col>
                <b-col md="7"><small><strong>{{ data.item.tgl_pho }}</strong></small></b-col>
              </b-row>

              <b-row>
                <b-col md="5"><small>Tanggal FHO</small> </b-col>
                <b-col md="7"><small><strong>{{ data.item.tgl_fho }}</strong></small></b-col>
              </b-row>

              <b-row>
                <b-col md="5"><small> </small> </b-col>
                <b-col md="7">
                  <b-badge variant="info" block size="sm" v-if="data.item.nilai_kontrak > '1'" v-b-modal.modal-3
                    @click="DetailDatta(data.item)">
                    <feather-icon icon="GridIcon" size='15' /> Detail Kontrak
                  </b-badge></b-col>
              </b-row>


            </b-col>

          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  props: {
    rs_data: {}
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],

    }
  },
  computed: {
    items: {
      get: function () {
        return this.rs_data;
      },
      set: function (newValue) {
        return this.rs_data = newValue;
      }
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  emits: ['DetailDatta'],
  methods: {
    DetailDatta(val) {
      this.$emit('DetailDatta', val)
    },
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: index`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>