var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-1","title":"Tambah Paket Pekerjaan","ok-disabled":"","no-close-on-backdrop":"","size":"lg","hide-footer":""}},[_c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Kegiatan","label-for":"Nama Kegiatan"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Nama Kegiatan "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Nama Kegiatan"},model:{value:(_vm.input.nama_kegiatan),callback:function ($$v) {_vm.$set(_vm.input, "nama_kegiatan", $$v)},expression:"input.nama_kegiatan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Kategori Paket Pekerjaan","label-for":"username"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nama_pekerjaan","options":_vm.jenis_pekerjaan},model:{value:(_vm.input.id_pekerjaan),callback:function ($$v) {_vm.$set(_vm.input, "id_pekerjaan", $$v)},expression:"input.id_pekerjaan"}})],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nomor Kontrak","label-for":"Nomor Kontrak"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Nomor Kontrak"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"No Kontrak"},model:{value:(_vm.input.no_kontrak),callback:function ($$v) {_vm.$set(_vm.input, "no_kontrak", $$v)},expression:"input.no_kontrak"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nilai Kontrak","label-for":"Nilai Kontrak"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Anggaran"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('currency-input',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.input.pagu_anggaran),callback:function ($$v) {_vm.$set(_vm.input, "pagu_anggaran", $$v)},expression:"input.pagu_anggaran"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tanggal Kontrak","label-for":"Tanggal Kontrak"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Tanggal Kontrak"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null,"config":{ dateFormat: 'd-m-Y' }},model:{value:(_vm.input.tgl_kontrak),callback:function ($$v) {_vm.$set(_vm.input, "tgl_kontrak", $$v)},expression:"input.tgl_kontrak"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal PHO","label-for":"Tanggal PHO"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Tanggal PHO"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null,"config":{ dateFormat: 'd-m-Y' }},model:{value:(_vm.input.tgl_pho),callback:function ($$v) {_vm.$set(_vm.input, "tgl_pho", $$v)},expression:"input.tgl_pho"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tanggal FHO","label-for":"Tanggal FHO"}},[_c('validation-provider',{attrs:{"name":"Tanggal FHO"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null,"config":{ dateFormat: 'd-m-Y' }},model:{value:(_vm.input.tgl_fho),callback:function ($$v) {_vm.$set(_vm.input, "tgl_fho", $$v)},expression:"input.tgl_fho"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon","size":"15"}}),_vm._v(" Simpan ")],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.close()}}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"15"}}),_vm._v(" Keluar ")],1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }