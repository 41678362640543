<template>
  <div>
    <b-row class="match-height">
      <b-col md="8">
        <b-card>
          <div style="padding-top: -20px;">
            <v-select v-model="id_balai_bidang" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama"
              @input="load_data()" style="margin-bottom: -10px;" placeholder="Pilih Balali Bidang "
              :options="balai_bidang" />
          </div>
        </b-card>
      </b-col>
      <b-col md="4">
        <statistik :kontrak="rs_data[0].kontrak"></statistik>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <div v-if="rs_data['0']['selisih_kontrak']['selisih_kontrak'] != '0'">
            <tabel_data style="margin-top: -10px;" :rs_data="rs_data" @DetailDatta=DetailDatta></tabel_data>
          </div>

        </b-card>
      </b-col>
    </b-row>
    <tambah-kontrak :ruas_jalan="ruas_jalan" :kodisiJalan="kodisiJalan" :jenis_pekerjaan="jenis_pekerjaan"
      @SimpanAjuanKontrak="SimpanAjuanKontrak" @kondisi_ruas_jalan="kondisi_ruas_jalan"></tambah-kontrak>
    <ajukan-kontrak :rs_detail=rs_detail @simpanKontrak=simpanKontrak></ajukan-kontrak>
    <detail_kontrak :rs_detail=rs_detail></detail_kontrak>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import statistik from './component/Statistik.vue'
import tabel_data from './component/tabel.vue'
import {
  BTabs,
  BTab, BCard, BCol, BRow
} from 'bootstrap-vue'
import TambahKontrak from './component/TambahKontrak.vue'
import AjukanKontrak from './component/AjukanKontrak.vue'
import detail_kontrak from './component/DetaiKontrak.vue'
export default {
  components: {
    BTabs, vSelect, statistik, tabel_data,
    BTab, BCard, BCol, BRow,
    TambahKontrak,
    AjukanKontrak, detail_kontrak,
  },
  props: {

  },
  data() {
    return {
      rs_data: [],
      rs_detail: [],
      ruas_jalan: [],
      kodisiJalan: {},
      jenis_pekerjaan: {},
      balai_bidang: [],
      id_balai_bidang: {}
    }
  },
  mixins: [Base],
  mounted() {
    this.load_data();
    this.load_ruas_jalan();
    this.master_jenis_pekerjaan();
    this.bidang_bpj()

  },
  methods: {
    async SimpanAjuanKontrak(input, jalan) {
      console.log(jalan)
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/kontrak/insert_kontrak_plus',
        data: {
          nama_kegiatan: input.nama_kegiatan,
          keterangan: input.keterangan,
          pagu_anggaran: input.pagu_anggaran,
          panjang_jalan: input.panjang_jalan,
          id_rus_jalan: input.id_ruas_jalan.id_ruas_jalan,
          id_jenis_pekerjaan: input.id_pekerjaan.id_pekerjaan,
          jalan: jalan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.load_data();
          self.notification('warning', "", response.data.message);

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async bidang_bpj() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/kontrak/bidang_bpj',
        data: {
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.balai_bidang = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    async master_jenis_pekerjaan() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/kontrak/master_jenis_pekerjaan',
        data: {
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.jenis_pekerjaan = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async kondisi_ruas_jalan(id_ruas_jalan) {
      console.log(id_ruas_jalan)
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/kontrak/kondisi_ruas_jalan',
        data: {
          id_ruas_jalan: id_ruas_jalan.id_ruas_jalan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.kodisiJalan = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    async load_ruas_jalan() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/kontrak/load_ruas_jalan',
        data: {
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.ruas_jalan = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },


    async simpanKontrak(fileUpload, input, id_usulan_kegiatan) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/kontrak/insert_kontrak',
        data: {
          id_usulan_kegiatan: id_usulan_kegiatan,
          file_kontrak: fileUpload,
          tgl_kontrak: input.tgl_kontrak,
          nilai_kontrak: input.nilai_kontrak,
          no_kontrak: input.no_kontrak,
          tgl_pho: input.tgl_pho,
          tgl_fho: input.tgl_fho,
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.load_data();
          self.notification('warning', " ", response.data.message);

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    DetailDatta(val) {
      this.rs_detail = val
    },
    async load_data() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/kontrak/load_data_laporan',
        data: {
          id_balai_bidang: this.id_balai_bidang.id_bidang
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_data = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  computed: {
    // items: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>