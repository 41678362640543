<template>
  <div>
    <b-modal id="modal-4" title="Data Kontrak" ok-disabled no-close-on-backdrop size="lg" hide-footer>
      <b-card-text>
        <b-row>
          <b-col md="12">
            <!-- <h5>
              <small><strong>Tujuan : </strong> {{ rs_detail.tujuan }}</small><br>
              <small><strong>Indikator Tujuan : </strong> {{ rs_detail.indikator_tujuan }}</small><br>
              <small><strong>Sasaran : </strong> {{ rs_detail.sasaran }}</small><br>
              <small><strong>Indikator Sasaran : </strong> {{ rs_detail.indikator_sasaran }}</small><br>
            </h5>
            <hr>
            <h5><small>Kode/Nama Kegiatan</small><br>
              <strong>{{ rs_detail.kode_sub_kegiatan }} - {{ rs_detail.nama_sub_kegiatan }}</strong><br>
              <small><strong>indikatror : </strong></small><small>{{ rs_detail.indikatror }}</small><br>
              <small><strong>Kinerja : </strong></small><small>{{ rs_detail.kinerja }}</small>
              (<small>{{ rs_detail.satuan_sub_kegiatan }})</small><br>
            </h5>
            <hr> -->
            <b-row>
              <b-col md="8">


                <h6 class="text-primary"> <small><strong>Nama Pekerjaan : </strong></small><small>{{
                  rs_detail.nama_pekerjaan }} <br><strong>{{ rs_detail.nm_balai_bidang }}</strong></small>
                  <br><small><strong>Jenis Pekerjaan {{ rs_detail.jenis_pekerjaan }}</strong></small>
                </h6>
                <h6 class="text-primary"> </h6>
                <h6 class="text-primary"> <small><strong>Detail Pekerjaan
                    </strong></small><small>{{ rs_detail.detail_lokasi }}{{
                      rs_detail.nama_ruas_jalan }} - {{ rs_detail.nm_wil }}</small> <br>
                  <small><strong>Target Pekerjaan
                    </strong></small><small>{{ rs_detail.target_pekerjaan }} - {{
                      rs_detail.satuan }} </small>
                </h6>

                <!-- 
                <h5> <small>Jenis Pekerjaan {{ rs_detail.jenis_pekerjaan }}</small><br>
                  <small>Nama Pekerjaan {{ rs_detail.nama_pekerjaan }}</small><br>
                  <small> Target Pekerjaan {{ rs_detail.target_pekerjaan }} {{ rs_detail.satuan
                  }}</small><br>
                  <small>Lokasi {{ rs_detail.no_ruas_jalan }} - {{ rs_detail.nama_ruas_jalan }}
                    {{ rs_detail.nm_wil }}</small><br>
                  <small v-if="rs_detail.kontruksi[0]['panjang_perbaikan'] != 0">Panjang {{
                    rs_detail.kontruksi[0]['panjang_perbaikan'] }} {{ rs_detail.satuan }}</small><br>
                </h5> -->
              </b-col>
              <b-col md="4">
                <small>Pagu Anggaran</small>
                <h5 style="font-size: 18px;"><strong class="text-primary"><sup>Rp. </sup>{{ Rp(rs_detail.pagu_usulan)
                }}</strong></h5>
              </b-col>
            </b-row>
            <hr>
            <h5><feather-icon icon="GridIcon" size='15' /> Input Kontrak</h5>
            <b-row>
              <b-col>
                <validation-observer ref="simpleRules">
                  <b-form>
                    <b-row>
                      <!--  This field is required-->
                      <b-col md="6">
                        <b-form-group label="Nomor Kontrak" label-for="Nomor Kontrak">
                          <validation-provider #default="{ errors }" rules="required" name="Nomor Kontrak">
                            <b-form-input v-model="input.no_kontrak" :state="errors.length > 0 ? false : null"
                              placeholder="No Kontrak" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group label="Nilai Kontrak" label-for="username">
                          <validation-provider #default="{ errors }" rules="required" name="First Name">
                            <currency-input v-model="input.nilai_kontrak" class="form-control"
                              :state="errors.length > 0 ? false : null"></currency-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group label="File Kontrak" label-for="File Kontrak">
                          <b-form-file v-model="file" placeholder="File type file PDF" ref="file" id="customFile"
                            @input="uploadFile" drop-placeholder="Upload File Kontrak (.PDF)" />
                          <i><small>**pastikan extensi file dalam bentuk .pdf</small></i>
                        </b-form-group>

                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Tanggal Kontrak" label-for="Tanggal Kontrak">
                          <validation-provider #default="{ errors }" rules="required" name="Tanggal Kontrak">
                            <flat-pickr v-model="input.tgl_kontrak" :state="errors.length > 0 ? false : null"
                              :config="{ dateFormat: 'd-m-Y' }" class="form-control" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                        <b-form-group label="Tanggal PHO" label-for="Tanggal PHO">
                          <validation-provider #default="{ errors }" rules="required" name="Tanggal PHO">
                            <flat-pickr v-model="input.tgl_pho" :state="errors.length > 0 ? false : null"
                              :config="{ dateFormat: 'd-m-Y' }" class="form-control" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                        <b-form-group label="Tanggal FHO" label-for="Tanggal FHO">
                          <validation-provider #default="{ errors }" name="Tanggal FHO">
                            <flat-pickr v-model="input.tgl_fho" :state="errors.length > 0 ? false : null"
                              :config="{ dateFormat: 'd-m-Y' }" class="form-control" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>


                      </b-col>
                      <b-col cols="12">
                        <b-button variant="primary" type="submit" @click.prevent="validationForm">
                          Submit
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>

              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required
} from '@validations'
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BRow, BCol, BCard, BAvatar, BBadge, BFormFile, BFormGroup, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CurrencyInput from '@/views/bpj/ajuan_kegiatan/usulan_kegiatan/component/CurrencyInput.vue'
import axios from 'axios'

export default {
  components: {
    BButton, required, ValidationProvider, ValidationObserver,
    BModal, BFormFile, BFormGroup, BFormInput,
    BAlert, BRow, BCol, BCard, BAvatar, BBadge,
    CurrencyInput, flatPickr
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      input: {},
      fileUpload: "",
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["simpanKontrak"],
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit("simpanKontrak", this.fileUpload, this.input, this.rs_detail.id_usulan_kegiatan)
          this.close();
        }
      })
    },
    uploadFile() {
      const isAuthenticated = localStorage.getItem('token_auth');
      this.file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append('file', this.file);
      const self = this;
      axios.post('/upload/kontrak', formData, {
        headers: {
          'Authorization': isAuthenticated,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
          self.file = {};
          self.fileUpload = response.data.result.nm_file

        })
        .catch(function (error) {
          console.log(error);
        });

    },
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    close() {
      this.input = {}
      this.$root.$emit('bv::hide::modal', 'modal-4')
    }
  },
  props: {
    rs_detail: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>